.qq-uploader {
    position: relative;
    width: 100%;
}
.qq-upload-button {
    display: block;
    width: 105px;
    padding: 7px 0;
    text-align: center;
    background: #880000;
    border-bottom: 1px solid #DDD;
    color: #FFF;
}
.qq-upload-button-hover {
    background: #CC0000;
}
.qq-upload-button-focus {
    outline: 1px dotted #000000;
}
.qq-upload-drop-area, .qq-upload-extra-drop-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 30px;
    z-index: 2;
    background: #FF9797;
    text-align: center;
}
.qq-upload-drop-area span {
    display: block;
    position: absolute;
    top: 50%;
    width: 100%;
    margin-top: -8px;
    font-size: 16px;
}
.qq-upload-extra-drop-area {
    position: relative;
    margin-top: 50px;
    font-size: 16px;
    padding-top: 30px;
    height: 20px;
    min-height: 40px;
}
.qq-upload-drop-area-active {
    background: #FF7171;
}
.qq-upload-list {
    margin: 0;
    padding: 0;
    list-style: none;
}
.qq-upload-list li {
    margin: 0;
    padding: 9px;
    line-height: 15px;
    font-size: 16px;
    background-color: #FFF0BD;
}
.qq-upload-file, .qq-upload-spinner, .qq-upload-size,
.qq-upload-cancel, .qq-upload-retry, .qq-upload-failed-text,
.qq-upload-delete, .qq-upload-pause, .qq-upload-continue {
    margin-right: 12px;
    display: inline;
}
.qq-upload-file {
}
.qq-upload-spinner {
    display: inline-block;
    background: url("/images/icons/loading.gif");
    width: 15px;
    height: 15px;
    vertical-align: text-bottom;
}
.qq-drop-processing {
    display: block;
}
.qq-drop-processing-spinner {
    display: inline-block;
    background: url("/images/icons/processing.gif");
    width: 24px;
    height: 24px;
    vertical-align: text-bottom;
}

.qq-upload-delete, .qq-upload-pause, .qq-upload-continue {
    display: inline;
}

.qq-upload-retry, .qq-upload-delete, .qq-upload-cancel,
.qq-upload-pause, .qq-upload-continue {
    color: #000000;
}

.qq-upload-size, .qq-upload-cancel, .qq-upload-retry,
.qq-upload-delete, .qq-upload-pause, .qq-upload-continue {
    font-size: 12px;
    font-weight: normal;
}
.qq-upload-failed-text {
    display: none;
    font-style: italic;
    font-weight: bold;
}
.qq-upload-failed-icon {
    display:none;
    width:15px;
    height:15px;
    vertical-align:text-bottom;
}
.qq-upload-fail .qq-upload-failed-text {
    display: inline;
}
.qq-upload-retrying .qq-upload-failed-text {
    display: inline;
    color: #D60000;
}
.qq-upload-list li.qq-upload-success {
    background-color: #5DA30C;
    color: #FFFFFF;
}
.qq-upload-list li.qq-upload-fail {
    background-color: #D60000;
    color: #FFFFFF;
}
.qq-progress-bar {
    display: block;
    background: -moz-linear-gradient(top,  rgba(30,87,153,1) 0%, rgba(41,137,216,1) 50%, rgba(32,124,202,1) 51%, rgba(125,185,232,1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(30,87,153,1)), color-stop(50%,rgba(41,137,216,1)), color-stop(51%,rgba(32,124,202,1)), color-stop(100%,rgba(125,185,232,1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  rgba(30,87,153,1) 0%,rgba(41,137,216,1) 50%,rgba(32,124,202,1) 51%,rgba(125,185,232,1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  rgba(30,87,153,1) 0%,rgba(41,137,216,1) 50%,rgba(32,124,202,1) 51%,rgba(125,185,232,1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  rgba(30,87,153,1) 0%,rgba(41,137,216,1) 50%,rgba(32,124,202,1) 51%,rgba(125,185,232,1) 100%); /* IE10+ */
    background: linear-gradient(to bottom,  rgba(30,87,153,1) 0%,rgba(41,137,216,1) 50%,rgba(32,124,202,1) 51%,rgba(125,185,232,1) 100%); /* W3C */
    width: 0%;
    height: 15px;
    border-radius: 6px;
    margin-bottom: 3px;
}

.qq-total-progress-bar {
    height: 25px;
    border-radius: 9px;
}

.qq-total-progress-bar-container {
    margin: 9px;
}

INPUT.qq-edit-filename {
    position: absolute;
    opacity: 0;
    filter: alpha(opacity=0);
    z-index: -1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.qq-upload-file.qq-editable {
    cursor: pointer;
}

.qq-edit-filename-icon.qq-editable {
    display: inline-block;
    cursor: pointer;
}

INPUT.qq-edit-filename.qq-editing {
    position: static;
    margin-top: -5px;
    margin-right: 10px;
    margin-bottom: -5px;

    opacity: 1;
    filter: alpha(opacity=100);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.qq-edit-filename-icon {
    display: none;
    background: url("/images/icons/edit.gif");
    width: 15px;
    height: 15px;
    vertical-align: text-bottom;
    margin-right: 5px;
}

.qq-hide {
    display: none;
}

/* <dialog> element styles */
.qq-uploader DIALOG {
    display: none;
}

.qq-uploader DIALOG[open] {
    display: block;
}

.qq-uploader DIALOG {
    display: none;
}

.qq-uploader DIALOG[open] {
    display: block;
}

.qq-uploader DIALOG .qq-dialog-buttons {
    text-align: center;
    padding-top: 10px;
}

.qq-uploader DIALOG .qq-dialog-buttons BUTTON {
    margin-left: 5px;
    margin-right: 5px;
}

.qq-uploader DIALOG .qq-dialog-message-selector {
    padding-bottom: 10px;
}

.qq-uploader DIALOG::backdrop {
    background-color: rgba(0, 0, 0, 0.7);
}