 /* ######### Drop Down ULs CSS ######### */

.ddsubmenustyle, .ddsubmenustyle ul{ /*topmost and sub ULs, respectively*/
font: normal 11px Arial;
margin: 0;
padding: 0;
position: absolute;
left: 0;
top: 0;
list-style-type: none;
background: white;
border: 1px solid #BACAE1;
border-bottom-width: 0;
visibility: hidden;
z-index: 100;
}
.ddsubmenustyle li
{
    background-color: White;
}

.ddsubmenustyle li a{
display: block;
width: 160px;
color: black;
background-color: white;
text-decoration: none;
padding: 4px 5px;
border-bottom: 1px solid #BACAE1;
}

* html .ddsubmenustyle li a{ /*IE6 CSS hack*/
display: inline-block;
}

.ddsubmenustyle li a:hover{
background-color: #BACAE1;
color: black;
}

/* ######### Neutral CSS  ######### */

.downarrowpointer{ /*CSS for "down" arrow image added to top menu items*/
padding-left: 4px;
border: 0;
}

.rightarrowpointer{ /*CSS for "right" arrow image added to drop down menu items*/
position: absolute;
padding-top: 3px;
left: 100px;
border: 0;
}

.ddiframeshim{
position: absolute;
z-index: 500;
background: transparent;
border-width: 0;
width: 0;
height: 0;
display: block;
}

/* ######### Matt Black Strip Main Menu Bar CSS ######### */

.mattblackmenu ul{
margin: 0;
padding: 0;
font: bold 11px Arial;
list-style-type: none;
border: 1px solid gray;
background: #FFFFFF;
overflow: hidden;
width: 150px;
}

.mattblackmenu li{
display: inline;
margin: 0;
background:#FF0000;
}

.mattblackmenu li a{
float: left;
display: block;
text-decoration: none;
margin: 0;
padding: 3px 8px; /*padding inside each tab*/
color: black;
background: #FFFFFF;
width:150px;
display:block;
}

.mattblackmenu li a:visited{
color: black;
}

.mattblackmenu li a:hover{
background: #FFFFFF; /*background of tabs for hover state */
}

.mattblackmenu a.selected{
background: #FFFFFF; /*background of tab with "selected" class assigned to its LI */
}