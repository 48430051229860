.qq-uploader { position:relative; width: 100%;}
.qq-InputField {filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);}
.qq-upload-button {
    display:block; /* or inline-block */
    width: 105px; padding: 7px 0; text-align:center;    
    background:#880000; border-bottom:1px solid #ddd;color:#fff;
}
.qq-upload-button-hover {background:#cc0000;}
.qq-upload-button-focus {outline:1px dotted black;}

.qq-upload-drop-area {
    position:absolute; top:0; left:0; width:100%; height:100%; min-height: 70px; z-index:2;
    background:#FF9797; text-align:center; 
}
.qq-upload-drop-area span {
    display:block; position:absolute; top: 50%; width:100%; margin-top:-8px; font-size:16px;
}
.qq-upload-drop-area-active {background:#FF7171;}

.qq-upload-list-container { position: absolute; top: 30px; right: 0px; z-index: 9; padding: 8px; background-color: white; box-shadow: 0px 0px 10px 0px #999; border-radius: 3px; }
.qq-upload-list { margin: 0px; padding:0; list-style:none;}
.qq-upload-list li { margin:0; padding:0; line-height:15px; font-size:12px;}
.qq-edit-filename { margin: 5px 0px; }
.qq-upload-buttons { float: right; text-align: right; }
.qq-upload-buttons a { display: inline-block; text-decoration: underline; }
.qq-upload-cancel, .qq-upload-size { font-size: 11px; color: #666; }
.qq-upload-cancel { margin-right: 7px; }
.qq-upload-size { float: left; }
.qq-upload-status-text { clear: both; }

.qq-upload-file, .qq-upload-spinner, .qq-upload-failed-text {
    margin-right: 7px;
}

.qq-upload-file {}
.qq-upload-spinner {
    display: inline-block;
    background: url("/images/loading-small.gif");
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
}

.qq-upload-failed-text {display:none;}
.qq-upload-fail .qq-upload-failed-text {display:inline;}