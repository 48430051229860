

.treeview, .treeview ul { 
	padding: 0;
	margin: 0;
	list-style: none;
}

.treeview ul {
	background-color: white;
	margin-top: 4px;
}

.treeview .hitarea {
	background: url(/Images/Treeview/treeview-default.gif) -64px -25px no-repeat;
	height: 16px;
	width: 16px;
	margin-left: -16px;
	float: left;
	cursor: pointer;
}
/* fix for IE6 */
* html .hitarea {
	display: inline;
	float:none;
}

.treeview li { 
	margin: 0;
	padding: 3px 0pt 3px 16px;
}

.treeview a.selected {
	background-color: #eee;
}

#treecontrol 
{ 
  margin-bottom: 1px; 
  background-color: white; 
  border: 3px solid White;
  overflow-x: hidden;
}

.treeview .hover {
    color: red; 
    cursor: pointer; 
}

.treeview li {
    background: url(/Images/Treeview/treeview-default-line.gif) 0 0 no-repeat; 
}
.treeview li.collapsable, .treeview li.expandable {
    background-position: 0 -176px; 
}

.treeview .expandable-hitarea {
    background-position: -80px -3px; 
}

.treeview li.last {
    background-position: 0 -1766px 
}

.treeview li.lastCollapsable, .treeview li.lastExpandable {
    background-image: url(/Images/Treeview/treeview-default.gif); 
} 
 
.treeview li.lastCollapsable {
    background-position: 0 -111px 
}

.treeview li.lastExpandable {
    background-position: -32px -67px 
}

.treeview div.lastCollapsable-hitarea, .treeview div.lastExpandable-hitarea {
    background-position: 0; 
}

.treeview-red li {
    background-image: url(/Images/Treeview/treeview-black-line.gif); background-repeat: no-repeat;
}

.treeview-red .hitarea, .treeview-red li.lastCollapsable, .treeview-red li.lastExpandable {
    background-image: url(/Images/Treeview/treeview-black.gif); 
} 

.treeview-black li {
    background-image: url(/Images/Treeview/treeview-black-line.gif); 
}

.treeview-black .hitarea, .treeview-black li.lastCollapsable, .treeview-black li.lastExpandable {
    background-image: url(/scripts/imagesTreeview/treeview-black.gif); 
}  

.treeview-gray li {
    background-image: url(/Images/Treeview/treeview-gray-line.gif); 
}

.treeview-gray .hitarea, .treeview-gray li.lastCollapsable, .treeview-gray li.lastExpandable {
    background-image: url(/Images/Treeview/treeview-gray.gif); 
} 

.treeview-famfamfam li {
    background-image: url(/Images/Treeview/treeview-famfamfam-line.gif); 
}

.treeview-famfamfam .hitarea, .treeview-famfamfam li.lastCollapsable, .treeview-famfamfam li.lastExpandable {
    background-image: url(/Images/Treeview/treeview-famfamfam.gif); 
}

.treeview-modern li {
    background-image: url(/Images/Treeview/treeview-famfamfam-line.gif); 
}

.treeview-modern .hitarea, .treeview-modern li.lastCollapsable, .treeview-modern li.lastExpandable {
    background-image: url(/Images/Treeview/treeview-modern.gif); 
}


.filetree li {
    padding: 3px 0 2px 16px; 
}

.filetree span.folder, .filetree span.file {
     padding: 1px 0 1px 16px; display: block; 
}

.filetree span.folder {
    background: url(/Images/Treeview/folder.gif) 0 0 no-repeat; 
}

.filetree li.expandable span.folder {
    background: url(/Images/Treeview/folder-closed.gif) 0 0 no-repeat; 
}

.filetree span.file {
    background: url(/Images/Treeview/file.gif) 0 0 no-repeat; 
}
