.v-container{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    -webkit-transition: .6s ease-in;
	transition: .6s ease-in;
}

.v-toolbar{
    position: absolute;
    top: 0px;
    left: 0px;
    /*padding: 0px 64px 0px 64px;*/
    width: 100%;
    z-index: 999;
    opacity: 0.9;
    height: 64px;
}
.v-toolbar-btn{
    line-height: 64px;
    color: white;
    font-size: 30pt;
    text-align:center;
    z-index: 999;
    height: 64px;
    width: 64px;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    transition: .2s linear;
}
.v-close-btn{
    position: absolute;
    right: 64px;
}
.v-delete-btn{
    position: absolute;
    left: calc(50% - 32px);
    font-size: 40pt;
}
.v-toolbar-btn:hover{
    -webkit-transform: scale(1.3);
	transform: scale(1.3);
}
.v-overlay{
    display: none;
    overflow: hidden;
    background-color: black;
    opacity: 0.9;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 990;
}

.v-nav{
    opacity: 0;
    height:100%;
    width: 64px;
    position: absolute;
    background-color: white;
    z-index: 999;
    text-align: center;       
    font-size: 64px;
    cursor: pointer;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.v-nav:hover{
    opacity: 1;
}
.v-prev{
    left: 0px;
    top: 0px;
}
.v-next{
    right: 0px;
    top: 0px;
}
.v-img-container{
    opacity: 0;
    z-index: 995;
    position: absolute;
    top: 50%;
    left: 50%;
    border: 8px solid white;
}
.v-img{
    opacity: 0;
    z-index: 995;
    max-height: 100%;
    max-width: 100%;
}